<template>
  <b-card no-body class="overflow-hidden card-container">
    <b-row no-gutters class="">
      <b-col md="4" class="image-container">
        <b-card-img
          :src="imagenBase"
          alt="Image"
          class="rounded-0"
          height="100%"
        ></b-card-img>
      </b-col>
      <b-col md="8">
        <b-card-body :title="titulo.toUpperCase()" class="body-container">
          <b-card-text>
            {{ this.firstLetterToUpperCase(resumen) }}
          </b-card-text>
          <b-button @click="$router.push({ name: 'detalle_articulo', params: { id: id } })" variant="primary" style="width: 200px; margin: 0px auto;" >{{
            $t("messages.readMore")
          }}</b-button>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
export default {
  mixins: [Helpers],
  props: {
    titulo: {
      type: String,
      default: "",
    },
    ultimaActualizacion: {
      type: String,
      default: "",
    },
    resumen: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 0,
    },
    imagenBase: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatDate(fecha) {
      return new Date(fecha).toLocaleDateString("en-GB");
    },
  },
};
</script>

<style scoped>
.card-container {
  max-width: calc(80% - 30px);
  margin: 20px 0px;
  height: fit-content;
}
.image-container {
  min-height: calc(100%);
  width: 100%;
  padding: 0px;
}
.body-container {
  height: fit-content;
  min-height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
