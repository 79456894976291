<template>
  <div class="container-services">
    <!-- <ProgressBar
        mode="indeterminate"
        style="height:3px;width:100%"
        v-if="loading"
      /> -->
    <!-- <div class="container-title-services" v-if="articulosFiltered.length>0">
      <p @click="$router.push({ name: 'blog' }).catch(() => {})">
        {{ $t("nav.articles").toUpperCase() }}
      </p>
    </div> -->
    <div style="display: flex;justify-content: center;align-items: center;min-height: 50vh;width: 100%;" v-if="loading">
      <b-spinner :label="$t('messages.loading')" variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
    </div>

    <div class="container-cards p-pt-6" v-else>
      <articulo-horizontal-card v-for="(articulo, index) in articulosFiltered" :key="index" :titulo="articulo.titulo"
        :ultimaActualizacion="articulo.fecha_publicacion" :resumen="articulo.resumen" :id="articulo.id"
        :imagenBase="articulo.imagen" />
    </div>
  </div>
</template>
<script>
import ArticuloCard from "./PublicComponents/ArticuloCard.vue";
import ArticuloHorizontalCard from "./PublicComponents/ArticuloHorizontalCard.vue";
import ArticulosService from "@/services/articulos.service";
import { mapGetters } from "vuex";
export default {
  name: "blog",
  components: { "articulo-card": ArticuloCard, "articulo-horizontal-card": ArticuloHorizontalCard },
  data() {
    return {
      articulosService: null,
      articulos: [],
      loading: true,
    };
  },
  created() {
    this.articulosService = new ArticulosService();
  },
  mounted() {
    this.loading = true;
    this.articulosService
      .allArticulosForPublic()
      .then((result) => {
        if (result.data.data) {
          this.articulos = [...result.data.data];
        }
        this.loading = false;
      })
      .catch((err) => {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "danger",
          message: "Error al recuperar los artículos",
        });
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(["getUserData"]),
    articulosFiltered() {
      let idiomaSeleccionado = "spanish";
      switch (this.$i18n.locale) {
        case "es":
          idiomaSeleccionado = "spanish";
          break;
        case "en":
          idiomaSeleccionado = "english";
          break;
        default:
          break;
      }
      return this.articulos.filter((articulo) => {
        return articulo.idioma === idiomaSeleccionado;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
}

.container-title-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.container-title-services p {
  font-size: 1.9rem;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  letter-spacing: 3px;
  color: var(--primary-misty-oracle);
}

.container-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.boton-enlace {
  text-align: center;
  background-color: #083078;
  padding: 10px 0px;
  color: white;
  user-select: none;
  width: fit-content;
  height: fit-content;
  border-radius: 3px;
  margin-top: 40px;
  transition: 0.5s all;
  cursor: pointer;

  a {
    color: white;
    font-size: 16px;
    width: 100%;
    padding: 10px 20px;
  }
}

.boton-enlace:hover {
  background-color: #2b86d9;
}
</style>
