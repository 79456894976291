<template>
  <article class="card-own">
    <div
      class="card-own-header imageContainer"
      :style="`background-image: url(${imagenBase})`"
      @click="$router.push({ name: 'detalle_articulo', params: { id: id } })"
    ></div>
    <div class="card-own-title">
      <span @click="$router.push({name:'detalle_articulo',params: { id:id }})"
      style="font-size:15px;font-weight:bold;">{{ titulo.toUpperCase() }}</span>
    </div>
    <sub  class="card-own-sub">
      <span
        style="font-size: 12px"
        @click="$router.push({ name: 'detalle_articulo', params: { id: id } })"
        >Ultima actualización: {{ formatDate(ultimaActualizacion) }}</span
      >
    </sub>
    <div class="card-own-content">
      <p
        @click="$router.push({ name: 'detalle_articulo', params: { id: id } })"
      >
        {{ resumen.substr(0, 250) }}
      </p>
    </div>
    <div  class="card-own-footer">
      <div
        class="boton-leer-mas"
        @click="$router.push({ name: 'detalle_articulo', params: { id: id } })"
      >
        Leer más
      </div>
    </div>
  </article>
</template>

<script>
export default {
  data() {
    return {
      urlFiles: null,
    };
  },
  created() {
    this.urlFiles = process.env.VUE_APP_API_FILES;
  },
  props: {
    titulo: {
      type: String,
      default: "",
    },
    ultimaActualizacion: {
      type: String,
      default: "",
    },
    resumen: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 0,
    },
    imagenBase: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatDate(fecha) {
      return new Date(fecha).toLocaleDateString("en-GB");
    },
  },
};
</script>

<style scoped>
.card-own{
  width: 20em;
  cursor:pointer;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  min-height: 450px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-shadow: -1px 0px 7px 0px rgba(58, 58, 58, 0.75);
  -moz-box-shadow: -1px 0px 7px 0px rgba(58, 58, 58, 0.75);
  box-shadow: -1px 0px 7px 0px rgba(58, 58, 58, 0.75);
  border-radius: 6px;
}
.card-own-header{
  height: 30%;
}
.card-own-title{
  display: flex;
  flex-wrap: wrap;
  height: 15%;
  width: 100%;
  text-align: center;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b1b1b1;
}
.card-own-sub{
  display: flex;
  flex-wrap: wrap;
  height: 5%;
  width: 100%;
  text-align: center;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b1b1b1;
}
.card-own-content{
  height: 40%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
  justify-content: center;
  font-size: 15px;
}
.card-own-footer{
  height: 10%;
    display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  transition: 0.5s all;
}
.imageContainer:hover {
  opacity: 0.8;
}
.boton-leer-mas {
  background-color: #083078;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  border-radius: 3px;
  width: 77px;
  margin: 0px auto;
  cursor: pointer;
  user-select: none;
  transition: 0.5s all;
  text-align: center;
}
.boton-leer-mas:hover {
  background-color: #0e45aa;
}

</style>
